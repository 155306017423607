import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/pages/runtime/validate.js";
import netlify_45redirects_45global from "/opt/build/repo/middleware/netlify-redirects.global.ts";
import remove_45trailing_45slash_45global from "/opt/build/repo/middleware/remove-trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  netlify_45redirects_45global,
  remove_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}