import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexmUmp5szgIiMeta } from "/opt/build/repo/pages/bespoke/[slug]/index.vue?macro=true";
import { default as indexI8GqbJMHksMeta } from "/opt/build/repo/pages/bespoke/index.vue?macro=true";
import { default as catalogueso8YfMZHf7OMeta } from "/opt/build/repo/pages/catalogues.vue?macro=true";
import { default as indexFCYZ9M0oliMeta } from "/opt/build/repo/pages/category/[slug]/index.vue?macro=true";
import { default as companyow8jzhOiX9Meta } from "/opt/build/repo/pages/company.vue?macro=true";
import { default as index3kN4tWYNlIMeta } from "/opt/build/repo/pages/contacts/addresses/index.vue?macro=true";
import { default as _91region_93fAWUQgFHeNMeta } from "/opt/build/repo/pages/contacts/distributors/[region].vue?macro=true";
import { default as indexyslOmucNhCMeta } from "/opt/build/repo/pages/contacts/requests/fail/index.vue?macro=true";
import { default as indexin65ZpdllNMeta } from "/opt/build/repo/pages/contacts/requests/index.vue?macro=true";
import { default as index8sLCjNOwvVMeta } from "/opt/build/repo/pages/contacts/requests/sent/index.vue?macro=true";
import { default as indexHMGILH7kigMeta } from "/opt/build/repo/pages/designers/[slug]/index.vue?macro=true";
import { default as indexdifN49bxWYMeta } from "/opt/build/repo/pages/designers/index.vue?macro=true";
import { default as indexrtJizlqgYOMeta } from "/opt/build/repo/pages/finishes/[slug]/index.vue?macro=true";
import { default as indexzhNIAyuc5MMeta } from "/opt/build/repo/pages/finishes/index.vue?macro=true";
import { default as indexPPJe7iS3pHMeta } from "/opt/build/repo/pages/news/[slug]/index.vue?macro=true";
import { default as index8k4xMa35gmMeta } from "/opt/build/repo/pages/news/index.vue?macro=true";
import { default as cookietkU8Y3bi3uMeta } from "/opt/build/repo/pages/policy/cookie.vue?macro=true";
import { default as privacyeossGHdzHMMeta } from "/opt/build/repo/pages/policy/privacy.vue?macro=true";
import { default as indexr7YiYgaZ6nMeta } from "/opt/build/repo/pages/product-family/[slug]/index.vue?macro=true";
import { default as indexmZue8RSC0IMeta } from "/opt/build/repo/pages/product/[slug]/index.vue?macro=true";
import { default as indexBmiOq17LApMeta } from "/opt/build/repo/pages/projects/[slug]/index.vue?macro=true";
import { default as indexxtMqaql9AYMeta } from "/opt/build/repo/pages/projects/index.vue?macro=true";
import { default as researchSFScNlVmHgMeta } from "/opt/build/repo/pages/research.vue?macro=true";
import { default as collectionsf7iqOZtjFyMeta } from "/opt/build/repo/pages/typology/collections.vue?macro=true";
import { default as systemsMwGyMCbgxdMeta } from "/opt/build/repo/pages/typology/systems.vue?macro=true";
import { default as component_45stubtnz6PCFrJsMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubtnz6PCFrJs } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "bespoke-slug___it",
    path: "/it/bespoke/:slug()",
    meta: indexmUmp5szgIiMeta || {},
    component: () => import("/opt/build/repo/pages/bespoke/[slug]/index.vue")
  },
  {
    name: "bespoke-slug___en",
    path: "/en/bespoke/:slug()",
    meta: indexmUmp5szgIiMeta || {},
    component: () => import("/opt/build/repo/pages/bespoke/[slug]/index.vue")
  },
  {
    name: "bespoke___it",
    path: "/it/bespoke",
    meta: indexI8GqbJMHksMeta || {},
    component: () => import("/opt/build/repo/pages/bespoke/index.vue")
  },
  {
    name: "bespoke___en",
    path: "/en/bespoke",
    meta: indexI8GqbJMHksMeta || {},
    component: () => import("/opt/build/repo/pages/bespoke/index.vue")
  },
  {
    name: "catalogues___it",
    path: "/it/cataloghi",
    meta: catalogueso8YfMZHf7OMeta || {},
    component: () => import("/opt/build/repo/pages/catalogues.vue")
  },
  {
    name: "catalogues___en",
    path: "/en/catalogues",
    meta: catalogueso8YfMZHf7OMeta || {},
    component: () => import("/opt/build/repo/pages/catalogues.vue")
  },
  {
    name: "category-slug___it",
    path: "/it/categorie/:slug()",
    meta: indexFCYZ9M0oliMeta || {},
    component: () => import("/opt/build/repo/pages/category/[slug]/index.vue")
  },
  {
    name: "category-slug___en",
    path: "/en/categories/:slug()",
    meta: indexFCYZ9M0oliMeta || {},
    component: () => import("/opt/build/repo/pages/category/[slug]/index.vue")
  },
  {
    name: "company___it",
    path: "/it/azienda",
    meta: companyow8jzhOiX9Meta || {},
    component: () => import("/opt/build/repo/pages/company.vue")
  },
  {
    name: "company___en",
    path: "/en/company",
    meta: companyow8jzhOiX9Meta || {},
    component: () => import("/opt/build/repo/pages/company.vue")
  },
  {
    name: "contacts-addresses___it",
    path: "/it/contatti",
    meta: index3kN4tWYNlIMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/addresses/index.vue")
  },
  {
    name: "contacts-addresses___en",
    path: "/en/contacts",
    meta: index3kN4tWYNlIMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/addresses/index.vue")
  },
  {
    name: "contacts-distributors-region___it",
    path: "/it/contatti/distributori/:region()",
    meta: _91region_93fAWUQgFHeNMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/distributors/[region].vue")
  },
  {
    name: "contacts-distributors-region___en",
    path: "/en/contacts/distributors/:region()",
    meta: _91region_93fAWUQgFHeNMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/distributors/[region].vue")
  },
  {
    name: "contacts-requests-fail___it",
    path: "/it/contacts/requests/fail",
    meta: indexyslOmucNhCMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/requests/fail/index.vue")
  },
  {
    name: "contacts-requests-fail___en",
    path: "/en/contacts/requests/fail",
    meta: indexyslOmucNhCMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/requests/fail/index.vue")
  },
  {
    name: "contacts-requests___it",
    path: "/it/contatti/richiedi",
    meta: indexin65ZpdllNMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/requests/index.vue")
  },
  {
    name: "contacts-requests___en",
    path: "/en/contacts/requests",
    meta: indexin65ZpdllNMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/requests/index.vue")
  },
  {
    name: "contacts-requests-sent___it",
    path: "/it/contatti/richiedi/richiesta-inviata",
    meta: index8sLCjNOwvVMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/requests/sent/index.vue")
  },
  {
    name: "contacts-requests-sent___en",
    path: "/en/contacts/requests/request-sent",
    meta: index8sLCjNOwvVMeta || {},
    component: () => import("/opt/build/repo/pages/contacts/requests/sent/index.vue")
  },
  {
    name: "designers-slug___it",
    path: "/it/designers/:slug()",
    meta: indexHMGILH7kigMeta || {},
    component: () => import("/opt/build/repo/pages/designers/[slug]/index.vue")
  },
  {
    name: "designers-slug___en",
    path: "/en/designers/:slug()",
    meta: indexHMGILH7kigMeta || {},
    component: () => import("/opt/build/repo/pages/designers/[slug]/index.vue")
  },
  {
    name: "designers___it",
    path: "/it/designers",
    meta: indexdifN49bxWYMeta || {},
    component: () => import("/opt/build/repo/pages/designers/index.vue")
  },
  {
    name: "designers___en",
    path: "/en/designers",
    meta: indexdifN49bxWYMeta || {},
    component: () => import("/opt/build/repo/pages/designers/index.vue")
  },
  {
    name: "finishes-slug___it",
    path: "/it/finiture/:slug()",
    meta: indexrtJizlqgYOMeta || {},
    component: () => import("/opt/build/repo/pages/finishes/[slug]/index.vue")
  },
  {
    name: "finishes-slug___en",
    path: "/en/finishes/:slug()",
    meta: indexrtJizlqgYOMeta || {},
    component: () => import("/opt/build/repo/pages/finishes/[slug]/index.vue")
  },
  {
    name: "finishes___it",
    path: "/it/finiture",
    meta: indexzhNIAyuc5MMeta || {},
    component: () => import("/opt/build/repo/pages/finishes/index.vue")
  },
  {
    name: "finishes___en",
    path: "/en/finishes",
    meta: indexzhNIAyuc5MMeta || {},
    component: () => import("/opt/build/repo/pages/finishes/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "news-slug___it",
    path: "/it/news/:slug()",
    meta: indexPPJe7iS3pHMeta || {},
    component: () => import("/opt/build/repo/pages/news/[slug]/index.vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    meta: indexPPJe7iS3pHMeta || {},
    component: () => import("/opt/build/repo/pages/news/[slug]/index.vue")
  },
  {
    name: "news___it",
    path: "/it/news",
    meta: index8k4xMa35gmMeta || {},
    component: () => import("/opt/build/repo/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    meta: index8k4xMa35gmMeta || {},
    component: () => import("/opt/build/repo/pages/news/index.vue")
  },
  {
    name: "policy-cookie___it",
    path: "/it/cookie-policy",
    meta: cookietkU8Y3bi3uMeta || {},
    component: () => import("/opt/build/repo/pages/policy/cookie.vue")
  },
  {
    name: "policy-cookie___en",
    path: "/en/cookie-policy",
    meta: cookietkU8Y3bi3uMeta || {},
    component: () => import("/opt/build/repo/pages/policy/cookie.vue")
  },
  {
    name: "policy-privacy___it",
    path: "/it/privacy-policy",
    meta: privacyeossGHdzHMMeta || {},
    component: () => import("/opt/build/repo/pages/policy/privacy.vue")
  },
  {
    name: "policy-privacy___en",
    path: "/en/privacy-policy",
    meta: privacyeossGHdzHMMeta || {},
    component: () => import("/opt/build/repo/pages/policy/privacy.vue")
  },
  {
    name: "product-family-slug___it",
    path: "/it/collezioni/:slug()",
    meta: indexr7YiYgaZ6nMeta || {},
    component: () => import("/opt/build/repo/pages/product-family/[slug]/index.vue")
  },
  {
    name: "product-family-slug___en",
    path: "/en/collections/:slug()",
    meta: indexr7YiYgaZ6nMeta || {},
    component: () => import("/opt/build/repo/pages/product-family/[slug]/index.vue")
  },
  {
    name: "product-slug___it",
    path: "/it/prodotto/:slug()",
    meta: indexmZue8RSC0IMeta || {},
    component: () => import("/opt/build/repo/pages/product/[slug]/index.vue")
  },
  {
    name: "product-slug___en",
    path: "/en/product/:slug()",
    meta: indexmZue8RSC0IMeta || {},
    component: () => import("/opt/build/repo/pages/product/[slug]/index.vue")
  },
  {
    name: "projects-slug___it",
    path: "/it/progetti/:slug()",
    meta: indexBmiOq17LApMeta || {},
    component: () => import("/opt/build/repo/pages/projects/[slug]/index.vue")
  },
  {
    name: "projects-slug___en",
    path: "/en/projects/:slug()",
    meta: indexBmiOq17LApMeta || {},
    component: () => import("/opt/build/repo/pages/projects/[slug]/index.vue")
  },
  {
    name: "projects___it",
    path: "/it/progetti",
    meta: indexxtMqaql9AYMeta || {},
    component: () => import("/opt/build/repo/pages/projects/index.vue")
  },
  {
    name: "projects___en",
    path: "/en/projects",
    meta: indexxtMqaql9AYMeta || {},
    component: () => import("/opt/build/repo/pages/projects/index.vue")
  },
  {
    name: "research___it",
    path: "/it/ricerca-sviluppo",
    meta: researchSFScNlVmHgMeta || {},
    component: () => import("/opt/build/repo/pages/research.vue")
  },
  {
    name: "research___en",
    path: "/en/research-development",
    meta: researchSFScNlVmHgMeta || {},
    component: () => import("/opt/build/repo/pages/research.vue")
  },
  {
    name: "typology-collections___it",
    path: "/it/collezioni",
    meta: collectionsf7iqOZtjFyMeta || {},
    component: () => import("/opt/build/repo/pages/typology/collections.vue")
  },
  {
    name: "typology-collections___en",
    path: "/en/collections",
    meta: collectionsf7iqOZtjFyMeta || {},
    component: () => import("/opt/build/repo/pages/typology/collections.vue")
  },
  {
    name: "typology-systems___it",
    path: "/it/sistemi",
    meta: systemsMwGyMCbgxdMeta || {},
    component: () => import("/opt/build/repo/pages/typology/systems.vue")
  },
  {
    name: "typology-systems___en",
    path: "/en/systems",
    meta: systemsMwGyMCbgxdMeta || {},
    component: () => import("/opt/build/repo/pages/typology/systems.vue")
  },
  {
    name: component_45stubtnz6PCFrJsMeta?.name,
    path: "/en-us/:pathMatch(.*)",
    component: component_45stubtnz6PCFrJs
  },
  {
    name: component_45stubtnz6PCFrJsMeta?.name,
    path: "/it/catalogue",
    component: component_45stubtnz6PCFrJs
  },
  {
    name: component_45stubtnz6PCFrJsMeta?.name,
    path: "/en/catalogue",
    component: component_45stubtnz6PCFrJs
  },
  {
    name: component_45stubtnz6PCFrJsMeta?.name,
    path: "/it/prodotti/:pathMatch(.*)",
    component: component_45stubtnz6PCFrJs
  },
  {
    name: component_45stubtnz6PCFrJsMeta?.name,
    path: "/en/products/:pathMatch(.*)",
    component: component_45stubtnz6PCFrJs
  },
  {
    name: component_45stubtnz6PCFrJsMeta?.name,
    path: "/it/blog-1/:pathMatch(.*)",
    component: component_45stubtnz6PCFrJs
  },
  {
    name: component_45stubtnz6PCFrJsMeta?.name,
    path: "/en/blog/:pathMatch(.*)",
    component: component_45stubtnz6PCFrJs
  },
  {
    name: component_45stubtnz6PCFrJsMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubtnz6PCFrJs
  }
]