import revive_payload_client_tq64X6RfXb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4hlGT92V4y from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rOiAXY30O5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_yALxjxY3O0 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.25.0_vite@5.4.10_@types+node@20.17.6_sass@1.8_yu5c6cyx242wxac65ukxo2qxta/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_DgAj9rNy5e from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2USaqIZl2v from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_PrGH909Hax from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_35MfjQBpQC from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_s6UOM6FXzE from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.25.0_typescript@5.5.3_vue@3.5.12_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_20gVoPcdUD from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_magicast@_poqhibbybcavwz7z2ahhuq3jji/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_27nvg32sTl from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.14.159_@parcel+watcher@2.5.0_@types+no_32dvhjcii7o4pfjb4hfnwnzu4m/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_WYpFA7ns9E from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.25.0_typescript@5.5.3_vue@3.5.12_typesc_slh76mxv3ztvoimxur45jy6fia/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_ZJbkuF1IfU from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.25.0_vue@3.5.12_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_XlU0betbmE from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.25.0_vue@3.5.12_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import leaflet_client_trb2AG60Fs from "/opt/build/repo/plugins/leaflet.client.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
export default [
  revive_payload_client_tq64X6RfXb,
  unhead_4hlGT92V4y,
  router_rOiAXY30O5,
  _0_siteConfig_yALxjxY3O0,
  payload_client_DgAj9rNy5e,
  navigation_repaint_client_2USaqIZl2v,
  check_outdated_build_client_PrGH909Hax,
  chunk_reload_client_35MfjQBpQC,
  plugin_vue3_s6UOM6FXzE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_20gVoPcdUD,
  plugin_27nvg32sTl,
  plugin_WYpFA7ns9E,
  switch_locale_path_ssr_ZJbkuF1IfU,
  i18n_XlU0betbmE,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  leaflet_client_trb2AG60Fs,
  sentry_client_GoGQuZo4Et
]