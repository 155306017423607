import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import queries from '@/graphql/craft/queries/index.js'

export const useCraftStore = defineStore('craft', () => {
  const { locales, locale } = useI18n()

  const groups = ref(null)
  const meta = ref({})

  // 'site' corrisponde alla lingua in Craft
  const site = computed(
    () => locales.value.find(({ code }) => locale.value === code)?.site
  )
  // 'marketId' è l'ID del mercato selezionato. Utilizziamo gli ID e non gli slug perché le relazioni del campo di inserimento in Craft avvengono tramite ID
  const marketId = computed(
    () =>
      groups.value?.markets.find((market) => market.key === locale.value)?.id
  )

  // Restituisco l'ID di altre configurazioni che possono essere dinamiche
  const typologyId = (slug) =>
    groups.value?.typologies.find((typology) => typology.slug === slug)?.id
  const lineId = (slug) =>
    groups.value?.lines.find((line) => line.slug === slug)?.id

  const categoryId = (slug) =>
    groups.value?.categories.find((category) => category.slug === slug)?.id

  // Restituisco la configurazione completa
  const configuration = computed(() => ({
    site: site.value,
    marketId: marketId.value,
    typologyId: (slug) => typologyId(slug),
    lineId: (slug) => lineId(slug),
    categoryId: (slug) => categoryId(slug),
    ...groups.value,
    meta: meta.value,
  }))

  // Estraggo la configurazione
  const fetchConfiguration = async () => {
    try {
      const { data } = await useAsyncQuery(
        queries.craft.craftState,
        {
          site: site.value,
        },
        {
          fetchPolicy: 'cache-and-network', // Usa la cache e aggiorna i dati in background
        }
      )

      // Raggruppo le varie configurazioni { typologies: [...], markets: [...], lines: [...]}
      groups.value = data.value?.entries?.reduce((acc, curr) => {
        const { sectionHandle, ...rest } = curr
        return {
          ...acc,
          [sectionHandle]: [...(acc[sectionHandle] || []), rest],
        }
      }, {})

      // Tutti gli altri dati relativi a settings o info generali
      meta.value = {
        ...meta.value,
        companyAddresses: data.value?.globalSet?.companyAddresses,
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    configuration,
    fetchConfiguration,
  }
})
