
// @ts-nocheck


export const localeCodes =  [
  "it",
  "en"
]

export const localeLoaders = {
  "it": [],
  "en": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config",
  "locales": [
    {
      "code": "it",
      "site": "it",
      "language": "it-IT",
      "name": "Global: It"
    },
    {
      "code": "en",
      "site": "en",
      "language": "en-GB",
      "name": "Global: En",
      "isCatchallLocale": false
    }
  ],
  "defaultLocale": "it",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://zavaluce.it",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "category/[slug]/index": {
      "it": "/categorie/[slug]",
      "en": "/categories/[slug]",
      "en-us": "/categories/[slug]"
    },
    "designers/index": {
      "it": "/designers",
      "en": "/designers",
      "en-us": "/designers"
    },
    "designers/[slug]/index": {
      "it": "/designers/[slug]",
      "en": "/designers/[slug]",
      "en-us": "/designers/[slug]"
    },
    "finishes/index": {
      "it": "/finiture",
      "en": "/finishes",
      "en-us": "/finishes"
    },
    "finishes/[slug]/index": {
      "it": "/finiture/[slug]",
      "en": "/finishes/[slug]",
      "en-us": "/finishes/[slug]"
    },
    "news/index": {
      "it": "/news",
      "en": "/news",
      "en-us": "/news"
    },
    "news/[slug]/index": {
      "it": "/news/[slug]",
      "en": "/news/[slug]",
      "en-us": "/news/[slug]"
    },
    "policy/privacy": {
      "it": "/privacy-policy",
      "en": "/privacy-policy",
      "en-us": "/privacy-policy"
    },
    "policy/cookie": {
      "it": "/cookie-policy",
      "en": "/cookie-policy",
      "en-us": "/cookie-policy"
    },
    "product/[slug]/index": {
      "it": "/prodotto/[slug]",
      "en": "/product/[slug]",
      "en-us": "/product/[slug]"
    },
    "product-family/[slug]/index": {
      "it": "/collezioni/[slug]",
      "en": "/collections/[slug]",
      "en-us": "/collections/[slug]"
    },
    "projects/index": {
      "it": "/progetti",
      "en": "/projects",
      "en-us": "/projects"
    },
    "projects/[slug]/index": {
      "it": "/progetti/[slug]",
      "en": "/projects/[slug]",
      "en-us": "/projects/[slug]"
    },
    "bespoke/index": {
      "it": "/bespoke",
      "en": "/bespoke",
      "en-us": "/bespoke"
    },
    "bespoke/[slug]/index": {
      "it": "/bespoke/[slug]",
      "en": "/bespoke/[slug]",
      "en-us": "/bespoke/[slug]"
    },
    "typology/collections": {
      "it": "/collezioni",
      "en": "/collections",
      "en-us": "/collections"
    },
    "typology/systems": {
      "it": "/sistemi",
      "en": "/systems",
      "en-us": "/systems"
    },
    "catalogues": {
      "it": "/cataloghi",
      "en": "/catalogues",
      "en-us": "/catalogues"
    },
    "company": {
      "it": "/azienda",
      "en": "/company",
      "en-us": "/company"
    },
    "contacts/addresses/index": {
      "it": "/contatti",
      "en": "/contacts",
      "en-us": "/contacts"
    },
    "contacts/requests/index": {
      "it": "/contatti/richiedi",
      "en": "/contacts/requests",
      "en-us": "/contacts/requests"
    },
    "contacts/requests/sent/index": {
      "it": "/contatti/richiedi/richiesta-inviata",
      "en": "/contacts/requests/request-sent",
      "en-us": "/contacts/requests/request-sent"
    },
    "contacts/distributors/[region]": {
      "it": "/contatti/distributori/[region]",
      "en": "/contacts/distributors/[region]",
      "en-us": "/contacts/distributors/[region]"
    },
    "research": {
      "it": "/ricerca-sviluppo",
      "en": "/research-development",
      "en-us": "/research-development"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "it",
    "site": "it",
    "language": "it-IT",
    "name": "Global: It",
    "files": []
  },
  {
    "code": "en",
    "site": "en",
    "language": "en-GB",
    "name": "Global: En",
    "isCatchallLocale": false,
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
